<template>
  <div>
    <section class="mb-16">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-card class="px-4 py-4 pb-4">
            <v-card-title class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <v-icon class="mr-2"
                  >mdi mdi-account-supervisor-circle-outline</v-icon
                >
                <span class="font-weight-bold mr-2"
                  >Recepción de Obras y Servicios</span
                >
              </div>
              <v-btn
                class="ml-auto"
                color="secondary" 
                @click="abrirModalEvaluacion"
                :disabled="tieneEvaluacion"
              >
                Evaluación del contratista
              </v-btn>
            </v-card-title>

            <h3 class="mt-8 mb-2">Datos de la recepción</h3>
            <v-divider />
            <v-col cols="12">
              <template>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(lote, index) in listaLote"
                    :key="index"
                  >
                    <v-expansion-panel-header>{{
                      lote.nombre
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <DataTableComponent
                        :headers="headers"
                        :items="lote.sub_procesos"
                        :inhabilitar_paginacion="true"
                        :tiene_paginacion="false"
                      >
                      </DataTableComponent>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>

            <v-row class="mt-4">
              <v-col cols="4">
                <v-menu transition="scale-transition" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        label="Fecha de recepción *"
                        prepend-inner-icon="mdi-calendar"
                        light
                        outlined
                        readonly
                        class="mr-4"
                        :error-messages="fechaRecepcionErrors"
                        :value="fecha_recepcionValue"
                        @change="$v.form.fecha_recepcion.$touch()"
                        @blur="$v.form.fecha_recepcion.$touch()"
                        :disabled="permiteEditarFecha"
                      />
                    </div>
                  </template>
                  <div @click.stop>
                    <vc-date-picker
                      v-model="form.fecha_recepcion"
                      mode="dateTime"
                      locale="es"
                      :disabled="permiteEditarFecha"
                    />
                  </div>
                </v-menu>
              </v-col>
              <v-col cols="4" v-if="!this.id_etapa">
                <money-text-field-component
                  label="Monto a pagar *"
                  v-model="form.monto_a_pagar"
                  ref="monto_retenido_var"
                  @blur="$v.form.monto_a_pagar.$touch"
                  :error-messages="montoAPagarErrors"
                  :disabled="permiteEditar"
                />
              </v-col>

              <v-col cols="4" align-center v-if="!this.id_etapa">
                <h2>
                  Monto restante
                  {{
                    Intl.NumberFormat("en-US", {
                      currency: "USD",
                      style: "currency",
                      minimumFractionDigits: 2,
                    }).format(form.monto_restante)
                  }}
                </h2>
              </v-col>
            </v-row>

            <MontosSeguimientoContractual
            @montoModificativa="obtenerMontoModificativa"
            />
            
            <template v-if="!permiteEditar">
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-file-input
                    accept="application/pdf"
                    clearable
                    class="py-2"
                    label="Acta de recepción *"
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="form.acta_recepcion"
                    :error-messages="actaRecepcionErrors"
                    @change="$v.form.acta_recepcion.$touch()"
                    @blur="$v.form.acta_recepcion.$touch()"
                    :disabled="permiteEditar"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <div class="d-flex justify-center flex-wrap mt-4 mb-2">
                <v-btn
                  v-for="(documento, index) in recepcion.data?.documentos"
                  :key="index"
                  class="primary mx-2" 
                  @click="descargarDocumento(documento?.DocumentoOrden?.ruta_archivo)"
                >
                  <v-icon class="white--text mr-2">mdi-download</v-icon>
                  {{ documento?.DocumentoOrden?.TipoDocumentoOrden?.nombre }}
                </v-btn>
              </div>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="d-flex justify-center align-center mt-4" style="gap: 16px;">
        <v-btn class="text-uppercase flex-grow-1 secondary" outlined @click="regresar()">Regresar</v-btn>
        <v-btn @click="abrirModalGuardar()" :disabled="validacionMonto" v-if="!permiteEditar || !permiteEditarFecha" class="text-uppercase flex-grow-1 primary">{{ buttonText }}</v-btn>
      </v-row>

    </section>
    <ConfirmationModalComponent
      :is-open="confirmarGuardarRecepcion"
      description="¿Desea guardar la recepción?"
      @cancel="cancelarConfirmacion"
      @confirm="crearRecepcion"
      :is-loading="guardarRecepcion.isLoading"
    />
    <evaluacionModal
      :isOpen="modalEvaluacion"
      @close="modalEvaluacion = false"
      :proveedor="providerData"
      @recargar="cargarLotesEtapa"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import moment from "moment";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
  isResponseSuccesful,
} from "@/utils/loadable";
import { ConfirmationModalComponent } from "@/components/utils";
import { convertToFormData } from "@/utils/data";
import evaluacionModal from "./evaluacionModal.vue";
import MontosSeguimientoContractual from "./components/montosSeguimientoContractual.vue";

export default {
  name: "recepcionParcialObrasServicios",
  components: {
    ConfirmationModalComponent,
    MoneyTextFieldComponent,
    DataTableComponent,
    MontosSeguimientoContractual,
    evaluacionModal,
  },
  validations() {
    return {
      form: {
        fecha_recepcion: { required },
        acta_recepcion: { 
          ...(!this.permiteEditarFecha && this.permiteEditar ? {} : { required })
         },
        monto_a_pagar: { 
          ...(!this.permiteEditarFecha && this.permiteEditar ? {} : { required })
         },
      },
    };
  },
  data() {
    return {
      obsData: {},
      providerData: null,
      id_etapa: null,
      id_proveedor: null,
      id_contrato: null,
      minDate: new Date(),
      menu: null,
      modalEvaluacion: false,
      confirmarGuardarRecepcion: false,
      datosRecepcionRegistrados: false,
      montoAPagar: null,
      listaLote: [],
      montoModificativa: 0,
      form: {
        cantidad_recibida: null,
        monto_a_pagar: null,
        id_tipo_recepcion: null,
        fecha_recepcion: null,
        acta_recepcion: null,
        monto_restante: null,
        monto_total: null,
        monto_recepcionado: null,
        acta_inconform: null,
      },
      etapasLote: createLoadable([]),
      recepcion: createLoadable([]),
      criterios: createLoadable([]),
      evaluacion: createLoadable([]),
      guardarRecepcion: createLoadable(null),
      esMontoCeroInvalido: false,
      esInvalido: false,
      headers: [
        {
          text: "OBS",
          align: "center",
          value: "especificacion",
        },
        {
          text: "Unidad de medida",
          align: "center",
          value: "unidad_medida",
        },
        {
          text: "Cantidad total",
          align: "center",
          value: "cantidad_total",
        },
        {
          text: "Monto total contratado",
          align: "center",
          value: "monto",
          sortable: false,
        },
        {
          text: "Plazo contractual",
          align: "center",
          value: "plazo_contractual",
          sortable: false,
        },
      ],
    };
  },
  emits: ["close"],
  computed: {
    ...mapState("seguimientoOrden", [
      "pagos",
    ]),
    ...mapState("SeguimientoContractual", [
      "evaluacionDefault",
      "guardarEvaluacion",
      "obtenerCriterios",
    ]),

    validacionMonto() {
      if (!this.permiteEditarFecha && this.permiteEditar) {
         this.esMontoCeroInvalido = false;
      }else{
         this.esInvalido = +this.form.monto_a_pagar > +this.form.monto_total || +this.form.monto_restante < 0 || +this.form.monto_a_pagar === 0;
      }
      return this.esInvalido || this.esMontoCeroInvalido;
    },

    buttonText() {
      return this.$route.params.id_etapa
        ? "Actualizar recepción"
        : "Registrar recepción";
    },
    fechaRecepcionErrors() {
      return new Validator(this.$v.form.fecha_recepcion).detect().getResult();
    },
    actaRecepcionErrors() {
      return new Validator(this.$v.form.acta_recepcion).detect().getResult();
    },
    fecha_recepcionValue() {
      if (!this.form.fecha_recepcion) return;
      return moment(this.form.fecha_recepcion).format("DD-MM-YYYY");
    },
    tieneEvaluacion() {
      return !!this.recepcion.data.resenia?.nota_promedio;
    },
    permiteEditarFecha(){
      return !isNaN(this.id_etapa) && this.pagos.some((pago) => pago != null);
    },
    permiteEditar() {
      return isNaN(this.id_etapa) ? false : true;
    },
    montoAPagarErrors() {
      return new Validator(this.$v.form.monto_a_pagar).detect().getResult();
    },
  },
  methods: {
    obtenerMontoModificativa(value) {      
      this.montoModificativa = value;
    },
    async descargarDocumento(documento, nombre) {
      this.descargarStandard(documento, nombre || "Documento de recepción");
    },
    ...mapActions("seguimientoOrden", ["getPagos"]),
    ...mapActions("SeguimientoContractual", [
      "enviarEvaluacion",
      "resetForm",
      "agregarCalificacion",
      "cargarCriterios",
    ]),
    calcularMonto(subProceso) {
      // Reinicia el monto a pagar en cualquier caso para manejar la lógica de limpieza de forma centralizada
      subProceso.monto_a_pagar = "";

      const cantidadRecibida = parseFloat(subProceso.cantidad_recibida);
      if (isNaN(cantidadRecibida)) return;

      if (cantidadRecibida > subProceso.cantidad_total) {
        return this.pushAppMessage({
          type: "error",
          message:
            "La cantidad recibida no puede ser mayor a la cantidad total.",
        });
      }
      const precioUnitario = Number(
        subProceso.monto / subProceso.cantidad
      ).toFixed(2);
      subProceso.monto_a_pagar = this.calcular(
        cantidadRecibida,
        precioUnitario
      );
    },
    calcular(cantidadRecibida, precioUnitario) {
      return (cantidadRecibida * precioUnitario).toFixed(2);
    },
    async cargarLotesEtapa() {
      toggleLoadable(this.etapasLote);
      const { data, headers } =
        await this.services.SeguimientoContractual.obtenerLotesEtapa(
          this.$route.params.id_orden,
          this.id_etapa
        );
      let obs = [];

      this.listaLote = data?.data.map((lista) => ({
        ...lista,
        sub_procesos: lista.sub_procesos.map((lista_sub_procesos) => {
          let precio_unitario = Number(
            lista_sub_procesos.gspb.monto / lista_sub_procesos.gspb.cantidad
          );
          let cantidad_entregada = Number(
            lista_sub_procesos.gspb.monto / precio_unitario
          );
          let cantidad_total = this.id_etapa  ? Number(lista_sub_procesos.gspb.cantidad) : Number(lista_sub_procesos.gspb.cantidad);

          const edicion = lista?.ajuste_orden;
          const edicionEncontrada = edicion.find((e) => e.id_lote === lista.id && e.id_sub_proceso === lista_sub_procesos.id)

          // const cantidadModificada = +edicion?.cantidad_actual ? +edicion?.cantidad_actual : 0;
          const cantidadModificada = edicionEncontrada?.cantidad_actual ? edicionEncontrada?.cantidad_actual : 0

          obs.push({
            id_sub_proceso_obs: lista_sub_procesos.id,
            cantidad: Number(cantidad_entregada) || 0,
            monto: lista_sub_procesos.gspb.monto.toFixed(2),
          });
          const cantidad_etapa =
            lista_sub_procesos?.DetalleEtapaOrdenContratos?.reduce(
              (acc, { cantidad }) => acc + Number(cantidad),
              0
            );

          this.form.monto_total += lista_sub_procesos.gspb.monto;
          this.form.monto_restante = this.form.monto_total;

          return {
            id_sub_proceso_obs: lista_sub_procesos.id,
            cantidad_total: Number(cantidad_total).toFixed(0),
            especificacion: lista_sub_procesos.especificacion,
            cantidad: lista_sub_procesos.gspb.cantidad,
            unidad_medida: lista_sub_procesos.Presentacion.nombre,
            plazo_contractual:
              lista_sub_procesos?.gspb?.GanadorOb?.co[0]
                ?.plazo_contractual || "No definido",
            monto: lista_sub_procesos.gspb.monto,
            cantidad_recibida: this.id_etapa ? cantidad_etapa : "",
            cantidad_anterior: this.id_etapa
              ? lista_sub_procesos.gspb.cantidad_recibida
              : "",
            monto_a_pagar: this.id_etapa
              ? this.calcular(
                  cantidad_etapa,
                  Number(precio_unitario).toFixed(2)
                )
              : "",
            id_etapa_contrato_orden: this.id_etapa || null,
          };
        }),
      }));

      this.obsData = obs;

      setLoadableResponse(this.etapasLote, data, headers);

      if (this.$route.params.id_etapa) {
        return this.obtenerRecepcion();
      }
      await this.obtenerRecepcionadoContrato();
    },
    async obtenerRecepcion() {
      toggleLoadable(this.recepcion);
      const { data } = await this.services.ContratoService.obtenerContratoEtapa(
        this.id_etapa
      );
      // this.form.monto_a_pagar = Number(data.data.cantidad_pagar).toFixed(2);

      setLoadableResponse(this.recepcion, data);
      const recepcion = this.recepcion.data;
      this.form.fecha_recepcion = recepcion.fecha_recepcion;

      await this.getPagos({
        idContrato: this.$route.params.id_orden,
      });

    },
    abrirModalGuardar() {
      this.$v.$touch();

      // Validaciones persionalizadas para validar que la evaluacion sea obligatoria.
      if (!this.$v.$invalid && !this.guardarEvaluacion.tieneUnaEvaluacion && (!this.tieneEvaluacion && !this.permiteEditarFecha)) {
        return this.pushAppMessage({
          type: "error",
          message: "Se requiere evaluar al contratista.",
          timeout: 7000,
        })
      }

      if (!this.$v.$invalid && +this.form.monto_restante < 0) {
        return this.pushAppMessage({
          type: "error",
          message: "El monto restante no puede ser una cantidad negativa.",
        });
      }
      if (this.$v.$invalid) return;
      let cantidad_recibida = null;
      let cantidad_total = null;

      this.listaLote.forEach((lista) => {
        lista.sub_procesos.forEach((subProceso) => {
          cantidad_recibida = subProceso.cantidad_recibida;
          cantidad_total = subProceso.cantidad_total;
        });
      });

      if (+cantidad_recibida > +cantidad_total) {
        this.pushAppMessage({
          type: "error",
          message: "La cantidad recibida es mayor a la cantidad total",
          timeout: 7000,
        });
      } else {
        this.confirmarGuardarRecepcion = true;
      }

      if(!this.permiteEditarFecha && this.permiteEditar){        
        this.confirmarGuardarRecepcion = true;
      }

      // if (cantidad_recibida === "") {
      //   this.pushAppMessage({
      //     type: "error",
      //     message: `El campo de la cantidad a recibir está vacío.`,
      //     timeout: 7000,
      //   });
      //   this.confirmarGuardarRecepcion = false;
      // }
    },
    async obtenerRecepcionadoContrato() {
      const { data } =
        await this.services.SeguimientoContractual.obtenerTotalRecepcionado(
          this.$route.params.id_orden
        );
      this.form.monto_recepcionado = data;
      this.recalcularMonto();
    },
    abrirModalEvaluacion() {
      this.modalEvaluacion = true;
      this.cargarCriterios();
      this.fetchProviderData();
    },
    async crearRecepcion() {
      if (this.form.monto_a_pagar > this.form.monto_total) {
        return this.pushAppMessage({
          type: "error",
          message: "El monto a pagar no puede ser mayor al monto total.",
        });
      }

      const evaluacionCalificacion = {
        observacion: this.guardarEvaluacion.observacion,
        adjunto: this.guardarEvaluacion.adjunto,
        notas: this.guardarEvaluacion.promedioNota
          ? this.guardarEvaluacion.notas
          : [],
        promedioNota: this.guardarEvaluacion.promedioNota,
        id_contrato: Number(this.$route.params.id_orden),
        id_proveedor: Number(this.$route.params.idProveedor),
      };

      let montosAPagar = [];

      this.listaLote.forEach((lista) => {
        lista.sub_procesos.forEach((subProceso) => {
          montosAPagar.push({
            monto_a_pagar: subProceso.monto_a_pagar,
          });
        });
      });

      const cantidad_sub_procesos = this.listaLote.flat().length;

      const obs = this.listaLote
        .map((lote) => [
          ...lote.sub_procesos.map((subproceso) => (
            {
            cantidad: subproceso.cantidad_total,
            monto: Number( this.form.monto_a_pagar / cantidad_sub_procesos).toFixed(2), //se pierde la trazabilidad de cuánto se paga en la recepción por cada servicio, sin embargo Nelson dijo que no era necesario, dado que para servicios importaba la trazabilida de la etapa únicamente
            id_sub_proceso_obs: subproceso.id_sub_proceso_obs,
            cantidad_anterior: subproceso.cantidad_anterior,
            id_lote: lote.id
          })),
        ])
        .flat();

      const dataTransformada = {
        cantidad_pagar: this.form.monto_a_pagar,
        cantidad: obs.reduce((acc, { cantidad }) => acc + Number(cantidad), 0),
        ...this.form,
        ...evaluacionCalificacion,
        obs,
        id_tipo_recepcion: Number(this.$route.query.tipo_recepcion),
      };

      const formData = convertToFormData({ ...dataTransformada });

      if (this.id_etapa) {
        await this.services.ContratoService.actualizarEtapaParcial(
          this.id_etapa,
          { fecha_recepcion: this.form.fecha_recepcion }
        );
        this.regresar();
      } else {
        toggleLoadable(this.guardarRecepcion);
        const { data } =
          await this.services.SeguimientoContractual.guardarContratoRecepcion(
            this.$route.params.id_orden,
            formData
          );
        setLoadableResponse(this.guardarRecepcion, data, {
          skipOnSuccess: true,
          showAlertOnSuccess: true,
        });

        this.confirmarGuardarRecepcion = false;

        if (!isResponseSuccesful(data)) return;
        this.regresar();
      }
    },
    limpiarFormulario() {
      this.$v.form.$reset();
      this.$refs.form.reset();
      this.$v.form.adjunto.$reset();
    },
    cancelarConfirmacion() {
      this.confirmarGuardarRecepcion = false;
      this.limpiarFormulario();
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.$route.params.idProveedor
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
    regresar() {
      this.resetForm();

      this.$router.replace({
        path: `/proceso-compra/seguimiento-orden/${this.$route.params?.id_orden}/${this.$route.params?.idProveedor}`,
      });
    },
    recalcularMonto() {
      const monto =
        this.form.monto_total -
        this.form.monto_a_pagar -
        this.form.monto_recepcionado + this.montoModificativa;
      this.form.monto_restante = monto.toFixed(2);

      if (this.form.monto_restante < 0) {
        return this.pushAppMessage({
          type: "error",
          message: "El monto restante no puede ser una cantidad negativa.",
        });
      }
    },
  },
  async created() {
    this.id_etapa = this.$route.params.id_etapa;
    this.tipo_recepcion = this.$route.query.tipo_recepcion;

    await this.cargarLotesEtapa();
  },
  watch: {
    "form.monto_a_pagar": {
      handler: function () {
        this.recalcularMonto();
        if (this.form.monto_a_pagar > this.form.monto_total) {
          return this.pushAppMessage({
            type: "error",
            message: "El monto a pagar no puede ser mayor al monto total.",
          });
        }
      },
      deep: true,
    },
  },
};
</script>
